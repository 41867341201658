import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import useUser from 'user-module/user/core/useUser'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useCart } from '../../core/cart.hooks'
import CartTotalLine from '../cart-lines/CartTotalLine'
import CartSavingsLineEcommerce from '../checkout-page/checkout-lines/CartSavingsLineEcommerce'
import CartSavingsLine from '../checkout-page/checkout-lines/CartSavingsLine'
import CartContent from './CartContent'
import CartSection from './CartSection'
import DirectPurchaseWrapper from './DirectPurchaseWrapper'
import EmptyCart from './EmptyCart'
import CartButtons from './CartButtons'
import CartBanner from '../cart-items/CartBanner'
import { Grid } from '@material-ui/core'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import Amount from 'shared-module/components/amount/Amount'
import RemoveCartItemButton from 'spypoint/src/checkout-module/order/ui/cart-page/RemoveCartItemButton'
import { useECommerceFlag } from '../../../../ecommerce-module/hooks/UseEcommerceFlag'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductItems, deleteItemFromBasket } from 'ecommerce-module/core/eCommerce.actions'
import { getProductItemsSelector, getBasketSelector, salesForceAddonsPlusQuantityEnabled } from 'ecommerce-module/core/eCommerce.selectors'
import ECommerceItemQuantitySelector from 'spypoint/src/checkout-module/order/ui/cart-lines/eCommerceItemQuantitySelector'
import EcommerceItemDiscountLine from 'spypoint/src/checkout-module/order/ui/cart-lines/ecommerceItemDiscountLine.js'
import ProductRandering from './ProductRandering'

const CartPage = () => {
  const { t } = useTranslation()
  const user = useUser()
  const userClubMembership = useUserClubMembership()
  const eCommerceEnabled = useECommerceFlag()
  const history = useHistory()
  const CART_PATH = '/cart'

  const {
    cartItems,
    cartItemsCount,
    isUnavailableItemInCart,
  } = useCart()

  const dispatch = useDispatch()
  const { basketId, productItems } = useSelector(getProductItemsSelector)
  const { basket } = useSelector(getBasketSelector)

  useEffect(() => {
    if (eCommerceEnabled) {
      dispatch(fetchProductItems())
    }
  }, [eCommerceEnabled, dispatch])

  const handleDeleteItem = async (basketId, itemId) => {
    try {
      productItems.filter(item => item.itemId !== itemId)
      dispatch(deleteItemFromBasket(basketId, itemId))
      history.push(CART_PATH)
    } catch (err) {
      console.error(err)
    }
  }
  const getTotalSaving = (productItems) => {
    let totalDiscount = 0
    productItems?.forEach(item => {
      item.priceAdjustments.forEach(adjustment => {
        totalDiscount += Math.abs(adjustment.price)
      })
    })
    return totalDiscount
  }

  if (!user.cartEnabled) {
    return <Redirect to="/" />
  }

  const AddonsPlusQuantity = productItems?.filter(obj => salesForceAddonsPlusQuantityEnabled.includes(obj.productId))
  const productItemsFilted = productItems?.filter(obj => !salesForceAddonsPlusQuantityEnabled.includes(obj.productId))
  return (
    <DirectPurchaseWrapper clubMembership={userClubMembership} basket={basket} productItems={productItems}>
      <AppBarContainer Icon={ShoppingCartIcon} title={t('menu.cart')} />
      <CartBanner isInsidersClubMember={userClubMembership.isMember} />
      { eCommerceEnabled
        ? (
            !productItems || productItems.length === 0
              ? (
                <EmptyCart />
                )
              : (
                <>
                  { basketId && (
                    <CartSection>
                      { AddonsPlusQuantity?.map((product, index) => (
                        <Grid key={product.productId || index}>
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle full bold>
                              <ProductRandering product={product.productId} />
                            </LineTitle>
                          </Grid>
                          <ECommerceItemQuantitySelector product={product} />
                          { userClubMembership.isMember && <EcommerceItemDiscountLine product={product} currency={user.currencyCode} /> }
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle bold>{ t('app:checkout_process.price') }</LineTitle>
                            <LineTitle bold align="right">
                              <Amount value={userClubMembership.isMember ? product?.priceAfterItemDiscount : product?.price} currencyCode={user.currencyCode} />
                            </LineTitle>
                          </Grid>
                          <RemoveCartItemButton
                            productId={product.itemId}
                            onDelete={() => {
                              handleDeleteItem(basketId, product.itemId)
                            }}
                          />
                          <CartDivider />
                        </Grid>
                      )) }
                      { productItemsFilted?.map((product, index) => (
                        <Grid key={product.productId || index}>
                          <Grid container justifyContent="space-between" spacing={3}>
                            { product.c_cameraName
                              ? (
                                <>
                                  <LineTitle full bold>
                                    <ProductRandering product={product.productId} />
                                  </LineTitle>
                                  <LineTitle>{ t('app:checkout_process.device') }</LineTitle>
                                  <LineTitle align="right" overflow="hidden" bold> { product.c_cameraName }</LineTitle>
                                </>
                                )
                              : (
                                <LineTitle full bold>
                                  <ProductRandering product={product.productId} />
                                </LineTitle>
                                ) }
                          </Grid>
                          { userClubMembership.isMember && <EcommerceItemDiscountLine product={product} currency={user.currencyCode} /> }
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle bold>{ t('app:checkout_process.price') }</LineTitle>
                            <LineTitle bold align="right">
                              <Amount value={userClubMembership.isMember ? product?.priceAfterItemDiscount : product?.price} currencyCode={user.currencyCode} />
                            </LineTitle>
                          </Grid>
                          <RemoveCartItemButton
                            productId={product.itemId}
                            onDelete={() => {
                              handleDeleteItem(basketId, product.itemId)
                            }}
                          />
                          <CartDivider />

                        </Grid>
                      )) }
                      {
                        basket && (
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle bold>{ t('app:checkout_process.cart.total_before_tax').toLocaleUpperCase() }</LineTitle>
                            <LineTitle align="right" bold>
                              <Amount value={basket?.productTotal} currencyCode={user.currencyCode} />
                            </LineTitle>
                          </Grid>
                        )
                      }
                      { userClubMembership.isMember && <CartSavingsLineEcommerce savings={getTotalSaving(productItems)} /> }
                    </CartSection>
                  ) }
                  <CartButtons isUnavailableItemInCart={null} />
                </>
                )
          )
        : (
            cartItemsCount === 0
              ? (
                <EmptyCart />
                )
              : (
                <>
                  <CartSection>
                    <CartContent products={cartItems.products} />
                    <CartTotalLine />
                    <CartSavingsLine />
                  </CartSection>
                  <CartButtons isUnavailableItemInCart={isUnavailableItemInCart} />
                </>
                )
          ) }
    </DirectPurchaseWrapper>
  )
}

export default CartPage
